.main {
    margin-top: 33px;
    display: flex;
    padding: 0 39px 0 26px;
}

.right {
    margin-left: 70px;
    width: 100%;
}

.rightNew {
    margin-left: 41px;
}

.navRightDark,
.navRightDark>div {
    background: #1a1a1a;
}

.navRightDark .btn-close {
    background-color: #fff;
}

.slick-initialized .slick-slide>div {
    display: flex;
    justify-content: center;
}

.field small {
    position: relative;
    top: -20px;
    left: 30px;
}

.signIn small {
    top: 0px;
}

.signInPanel {
    display: none !important;
}


/* Chart */

.recharts-wrapper .recharts-cartesian-grid-horizontal line,
.recharts-wrapper .recharts-cartesian-grid-horizontal line,
.recharts-wrapper .recharts-cartesian-grid-vertical line,
.recharts-wrapper .recharts-cartesian-grid-vertical line {
    stroke-opacity: 0 !important;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:first-child {
    stroke-opacity: 1 !important;
    stroke: #bebebe !important;
}

.recharts-cartesian-axis-tick {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000000;
}


/* Sidebar */

.search-bar {
    max-width: 367.89px;
    width: 100%;
    height: 50.17px;
    background-color: rgba(214, 214, 214, 0.19);
    border-radius: 11.4419px;
    position: relative;
    padding: 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 10.55px;
}

.search-bar-spacer {
    max-width: 367.89px;
    width: 100%;
    height: 50.17px;
    /* background-color: rgba(214, 214, 214, 0.19); */
    border-radius: 11.4419px;
    position: relative;
    padding: 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 10.55px;
}

.search-bar img {
    width: 21.12px;
    height: 21.12px;
}

.search-bar input {
    width: 100%;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 12.3221px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
}

.search-bar input::placeholder {
    color: rgba(0, 0, 0, 0.58);
}

.search-bar input:focus {
    outline: none;
}


/* Node Type */

.node-type {
    padding: 12px 28px;
    background-color: rgba(214, 214, 214, 0.19);
    border-radius: 11.4419px;
    margin: 0 10.86px;
}

.node-type-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9.28px;
}

.node-type-wrapper:last-child {
    margin-bottom: 0px;
}

.node-type-wrapper label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12.3221px;
    line-height: 18px;
    color: #000000;
}

.node-type-wrapper input {
    border: 1.7603px solid #0094d7;
}

.node-type-wrapper input:checked {
    background: #0094d7;
    border: 1.7603px solid #0094d7;
}


/* Cd Button */

.cd-btn {
    padding: 0 17px;
    margin: 0 0 18.09px;
}

.cd-btn-wrapper {
    width: 100%;
    height: 38.1px;
    background: #eaeaea;
    border: 0.264045px solid #d7d7d7;
    border-radius: 9.35761px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cd-btn-wrapper button {
    width: 50%;
    height: 38.1px;
    padding: 0;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #363636;
    border-radius: 9.35761px;
    background-color: transparent;
}

.cd-btn-wrapper button:nth-child(1) {
    margin-left: -1px;
}

.cd-btn-wrapper button:nth-child(2) {
    margin-right: -2px;
}

.cd-btn-active {
    background: linear-gradient(260.69deg, #2e1f8b -33.31%, #6d24e4 91.97%);
    color: #ffffff !important;
}


/* Panel Footer */

.panel-footer {
    max-width: 317px;
    width: 100%;
    height: 72px;
    left: 26px;
    top: 800px;
    background: #f1f1f1;
    border-radius: 22.7256px;
}

.panel-footer {
    padding: 17px 0;
}

.panel-footer-inner {
    max-width: 193px;
    width: 100%;
    margin: auto;
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-btn {
    border-radius: 9.35761px;
    border: none;
    padding: 0;
}

.search-btn-dark {
    background: rgba(56, 56, 56, 0.19);
}

.save-btn {
    width: 91px;
    height: 38px;
    border-radius: 9.35761px;
    border: none;
    padding: 0;
    border: 1px solid #2e1f8b;
}

.save-btn:hover {
    background: linear-gradient(260.69deg, #2e1f8b -33.31%, #6d24e4 91.97%);
    border: 0px solid transparent !important;
}

.save-btn span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    background: linear-gradient(260.69deg, #2e1f8b -33.31%, #6d24e4 91.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.save-btn:hover span {
    -webkit-text-fill-color: #fff;
    text-fill-color: #fff;
}

.clr-btn {
    max-width: 91px;
    width: 100%;
    height: 38px;
    background: #eaeaea;
    border: 0.264045px solid #d7d7d7;
    border-radius: 9.35761px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}


/* Card */

.nodeContainer {
    /* border: solid 1px red; */
    min-height: 1346px;
}

.card--header {
    width: 100%;
    height: 81px;
    background: #fdfdfd;
    border: 0.88015px solid #dbdbdb;
    border-radius: 22.7256px;
    margin-top: -1px;
    border-right: 0;
    border-left: 0;
    padding: 0 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0 6px;
}

.card-bubble {
    width: 38px;
    height: 38px;
    background: #f4f4f4;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.card-bubble img {
    width: 24px;
    height: 24px;
}

.card-bubble-active {
    position: relative;
}

.card-bubble-active::after {
    opacity: 0.2;
    background: linear-gradient(260.69deg, #2e1f8b -33.31%, #6d24e4 91.97%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    border-radius: 8px;
}

.card--middle {
    padding: 19px 0 21px;
    text-align: center;
}

.card--middle img {
    width: 102px;
    height: 102px;
    margin: 0 auto 23px;
}

.card--middle h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.socials-wrapper {
    width: 164px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 11px;
    height: 24px;
    margin: 0 auto;
}

.socials-wrapper button {
    border: none;
    background: transparent;
    height: 100%;
    padding: 0;
}

.socials-wrapper button img {
    width: 24px;
    height: 24px;
}

.socials-wrapper a {
    border: none;
    background: transparent;
    height: 100%;
    padding: 0;
}

.socials-wrapper a img {
    width: 24px;
    height: 24px;
}


/* Both btn */

.both-btn {
    padding: 0 17px;
    margin-top: 8.21px;
}

.both-btn-wrapper {
    width: 100%;
    height: 38.1px;
    background: #eaeaea;
    border: 0.264045px solid #d7d7d7;
    border-radius: 9.35761px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.both-btn-wrapper button {
    width: 33.333339%;
    height: 38.1px;
    padding: 0;
    font-family: "Poppins";
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #363636;
    border-radius: 9.35761px;
    background-color: transparent;
}

.both-btn-wrapper button:nth-child(1) {
    margin-left: -1px;
}

.both-btn-wrapper button:nth-child(3) {
    margin-right: -2px;
}

.both-btn-active {
    background: linear-gradient(260.69deg, #2e1f8b -33.31%, #6d24e4 91.97%);
    color: #ffffff !important;
}


/* Multi Sidebar  */

.p-slider .p-slider-range {
    background: linear-gradient(260.69deg, #2e1f8b -33.31%, #6d24e4 91.97%) !important;
}

.p-slider.p-slider-horizontal .p-slider-handle {
    background: #f0f0f0;
    border: 2px solid #02a1e9;
    box-shadow: 0px 0.88015px 3.5206px rgba(0, 0, 0, 0.05);
    width: 15.84px;
    height: 15.84px;
}

.p-slider.p-slider-horizontal {
    height: 7.04px !important;
}

.field-checkbox .p-checkbox {
    margin-right: 10px;
}


/* Dark Mode */

.field-checkbox-Dark .p-checkbox .p-checkbox-box {
    background: #161616;
}

.field-checkbox-Dark label {
    color: rgba(255, 255, 255, 0.6);
}

.darkMode {
    background: linear-gradient(180deg, #141414 0%, #000000 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-height: 100vh;
    overflow: hidden;
}

.darkModeNew {
    background: #0c0c0c;
}

.lightMode {
    background: #fafcfe;
    min-height: 100vh;
    overflow: hidden;
}

.lightModeNew {
    background: white;
}

.chartWrapperDark .recharts-responsive-container .recharts-wrapper>div>div {
    background: linear-gradient(180deg, #141414 0%, #000000 100%) !important;
}

.chartWrapperDark .recharts-responsive-container .recharts-wrapper>div>div>p {
    color: #fff;
}

.cd-btn-dark .cd-btn-wrapper {
    background: #222222;
    border: 0.26px solid rgba(42, 42, 42, 0.26);
}

.cd-btn-dark .cd-btn-wrapper button {
    color: #494949;
}

.both-btn-dark .both-btn-wrapper,
.cd-btn-dark .cd-btn-wrapper {
    background: #222222;
    border: 0.26px solid rgba(42, 42, 42, 0.26);
}

.both-btn-dark .both-btn-wrapper button,
.cd-btn-dark .cd-btn-wrapper button {
    color: #494949;
}

.node-type-dark {
    background: rgba(58, 58, 58, 0.19);
}

.node-type-dark .node-type-wrapper label {
    color: #fff;
}

.node-type-dark .node-type-wrapper .form-check-input {
    background: transparent;
}

.panel-footer-dark {
    background: #232323;
}

.panel-footer-dark .save-btn {
    border: 0.668401px solid rgba(255, 255, 255, 0.668401);
    background: transparent;
}

.panel-footer-dark .save-btn:hover {
    border: 0.668401px solid rgba(255, 255, 255, 0.668401) !important;
}

.panel-footer-dark .save-btn span {
    color: #ffffff;
    background: unset;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
}

.panel-footer-dark .clr-btn {
    border: 0.264045px solid rgba(42, 42, 42, 0.264045);
    background: transparent;
    color: #ffffff;
    background: unset;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
}

.card--header--dark {
    background: #232323;
    border: none;
}

.card--header--dark .card-bubble-active::after {
    background: linear-gradient(260.69deg, #2e1f8b -33.31%, #6d24e4 91.97%);
    opacity: 0.4;
}

.card--header--dark .card-bubble {
    background: rgba(56, 56, 56, 0.19);
}

.card--middle--dark h5 {
    color: #fff;
}

.search-bar-dark {
    background: rgba(56, 56, 56, 0.19);
}

.search-bar-dark input {
    color: rgba(253, 253, 253, 0.58);
}

.search-bar-dark input::placeholder {
    color: rgba(253, 253, 253, 0.38);
}

.sidePanelNodeActive {
    left: 26px !important;
    transition: all 3s !important;
}

.wrapperActive {
    left: 26px !important;
    transition: all 2s ease-in-out !important;
}

@media screen and (max-width: 1250px) {
    .right {
        margin-left: 0px;
    }
    .main {
        margin-top: 32px;
        padding: 0 37px 0 24px;
    }
}

@media screen and (max-width: 870px) {
    .search-bar {
        max-width: 264.89px;
    }
    .main {
        margin-top: 31px;
        padding: 0 36px 0 23px;
    }
}

@media screen and (max-width: 768px) {
    .search-bar {
        max-width: 100%;
    }
    .main {
        margin-top: 30px;
        padding: 0 34px 0 21px;
    }
}

@media screen and (max-width: 500px) {
    .search-bar {
        margin-bottom: 10px;
    }
    .main {
        margin-top: 28px;
        padding: 0 15px 0 12px;
    }
}